exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ambiti-intervento-contentful-ambiti-slug-js": () => import("./../../../src/pages/ambiti-intervento/{contentfulAmbiti.slug}.js" /* webpackChunkName: "component---src-pages-ambiti-intervento-contentful-ambiti-slug-js" */),
  "component---src-pages-azienda-js": () => import("./../../../src/pages/azienda.js" /* webpackChunkName: "component---src-pages-azienda-js" */),
  "component---src-pages-case-history-contentful-case-history-slug-js": () => import("./../../../src/pages/case-history/{contentfulCaseHistory.slug}.js" /* webpackChunkName: "component---src-pages-case-history-contentful-case-history-slug-js" */),
  "component---src-pages-case-history-index-js": () => import("./../../../src/pages/case-history/index.js" /* webpackChunkName: "component---src-pages-case-history-index-js" */),
  "component---src-pages-certificazioni-sicurezza-js": () => import("./../../../src/pages/certificazioni-sicurezza.js" /* webpackChunkName: "component---src-pages-certificazioni-sicurezza-js" */),
  "component---src-pages-contatti-index-js": () => import("./../../../src/pages/contatti/index.js" /* webpackChunkName: "component---src-pages-contatti-index-js" */),
  "component---src-pages-contatti-success-js": () => import("./../../../src/pages/contatti/success.js" /* webpackChunkName: "component---src-pages-contatti-success-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lavora-con-noi-index-js": () => import("./../../../src/pages/lavora-con-noi/index.js" /* webpackChunkName: "component---src-pages-lavora-con-noi-index-js" */),
  "component---src-pages-lavora-con-noi-success-js": () => import("./../../../src/pages/lavora-con-noi/success.js" /* webpackChunkName: "component---src-pages-lavora-con-noi-success-js" */),
  "component---src-pages-politica-aziendale-js": () => import("./../../../src/pages/politica-aziendale.js" /* webpackChunkName: "component---src-pages-politica-aziendale-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-servizi-contentful-servizi-slug-js": () => import("./../../../src/pages/servizi/{contentfulServizi.slug}.js" /* webpackChunkName: "component---src-pages-servizi-contentful-servizi-slug-js" */)
}

